<template>
  <v-select
    v-bind="$attrs"
    :items="availableItems"
    :label="label"
    v-model="selected"
    :item-text="itemText"
    item-value="Id"
    :clearable="clearable"
    :rules="rules"
    :loading="loading"
  >
    <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
      <slot :name="scopedSlotName" v-bind="slotData" />
    </template>
    <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
      <slot :name="slotName" />
    </template>
  </v-select>
</template>
<script>
  import { get, sync, commit, call } from 'vuex-pathify'
  export default {
    name: 'select-luDocumentType',
    model: {
      prop: 'value',
      event: 'change',
    },
    props: {
      label: {
        type: String,
        default: 'Document Type',
        },
      value: Number,
      clearable: {
        type: Boolean,
        default: false
      },
      includeInactive: {
        type: Boolean,
        default: false,
      },
      rules: Array,
      items: Array,
      //templateFIX: if there is more than one pick one to display
      itemText: {
        type: String,
        default: 'Description',
      },
      sortProperty: {
        type: String,
        default: null,
      },
    },
    data(){
      return {

      }
    },
    computed: {
      ...get('luDocumentType',['luDocumentTypes', 'loading']),
      availableItems() {
          var list = []
          var fullList = []
          if (this.items) {
            fullList = this.items
            list = this.items.filter((i) => true)
          } else {
            fullList = this.luDocumentTypes
            list = this.luDocumentTypes.filter((i) => true)
          }
          //filter for only active items
          if (!this.includeInactive) {
            list = list.filter((i) => i.IsActive )
          }
          // if existing record make sure the previous selection is available even if no longer active
          if (this.value > 0) {
            var selectedItem = fullList.find((i) => i.Id == this.value)
            if (!list.find((i) => i.Id == this.value) && selectedItem) {
              list.push(selectedItem)
            }
          }
          // sort by itemText column
          list.sort((a, b) => {
            //set the property to sort on
            let textField = this.sortProperty || this.itemText
            let sort = a[textField] > b[textField] ? 1 : -1
            return sort
          })
          return list
      },
      selected: {
        get() {
          if (this.value) {
            return this.value
          } else {
            return null
          }
        },
        set(newValue) {
          this.$emit('change', newValue)
        }
      }
    },
    created(){
      if (!this.items) {
        this.loadLuDocumentTypes()
      }
    },
    methods: {
      loadLuDocumentTypes: call('luDocumentType/loadLuDocumentTypes'),
    },
  }
</script>
<style>

</style>